body { font-family: "Montserrat", serif !important; }
figure { margin: 0; }
/*==== MAIN-BANNER ====*/
.main-banner { background: url('../assets/images/banner-bg.jpg') no-repeat; background-size: cover; background-position: center; background-attachment: fixed; margin-top: 100px; }
.main-banner .main-banner-in { padding: 50px 0; }
.title { color: #fff; font-size: 38px; }
.content { color: #ccc; font-size: 17px; }
.main-banner .main-banner-in .title { margin-bottom: 25px; }
.main-banner .main-banner-in .row { align-items: center; }
.main-banner .main-banner-in .banner-right img { height: 400px; width: 100%; object-fit: cover; border-radius: 10px; }
/*====== ABOUT-SEC =====*/
.about-sec { background: #481c9e; padding: 100px 0; }
.about-sec .content-box { background: #210b5c; border-radius: 10px; border: solid 1px #8c8484; padding: 50px 60px; }
.about-sec .content-box .title { margin-bottom: 40px; }
.about-grid .about-block h3 { font-size: 22px; color: #fff; margin-bottom: 12px; }
/*===== FEATURES-SEC =====*/
.feature-sec { background: url('../assets/images/features-banner.jpg') no-repeat; background-size: cover; background-position: center; padding: 100px 0; }
.feature-sec .title { margin-bottom: 30px; }
.feature-step .number { width: 55px; height: 44px; display: inline-block; text-align: center; line-height: 42px; background: rgb(60,19,109); background: linear-gradient(90deg, rgba(60,19,109,1) 0%, rgba(53,19,109,1) 47%, rgba(39,19,108,1) 100%); border-radius: 7px; border: solid 1px #4f4343; color: #fff; font-size: 21px; font-weight: 600; }
.feature-step { display: flex; margin-top: 30px; }
.feature-step .feature-cont { display: inline-block; color: #fff; padding-left: 19px; }
.feature-step .feature-cont h3 { font-size: 23px; color: #ccc; }
/*===== BENEFITS-BANNER ====*/
.benefits-sec { background: url('../assets/images/banner-bg.jpg') no-repeat; background-size: cover; background-position: center; padding: 20px 0; background-attachment: fixed; }
.benefits-sec .row { align-items: center; }
.benefits-sec .title { margin-bottom: 25px; }
.benefits-sec .benefits-sec-right figure img { width: 100%; border-radius: 10px; }
.benefits-sec-left { padding-right: 40px; }
.benefits-block .benefits-block-in { background: rgb(60,19,109); background: linear-gradient(90deg, rgba(60,19,109,1) 0%, rgba(53,19,109,1) 47%, rgba(39,19,108,1) 100%); border-radius: 10px; border: solid 1px #8c8484; padding: 22px; height: 100%; }
.benefits-block .benefits-block-in h3 { margin: 0; font-size: 19px; color: #fff; padding-bottom: 11px; }
.benefits-block .benefits-block-in .content { margin: 0; }
.benefits-block { padding: 10px; height: 240px; }
.benefits-sec-left .row { align-items: inherit; }
/*===== SUCCESS-BANNER ====*/

.success-stories-sec { text-align: center; background: url('../assets/images/success-stories-banner.jpg') no-repeat; background-size: cover; background-position: center; padding: 20px 0; background-attachment: fixed; padding: 100px 0; }
.success-stories-grid .success-stories-block .content { text-align: center; margin-bottom: 80px; }
.success-stories-grid .success-stories-block .content strong { display: block; color: #fff; }
.success-stories-sec .title { margin-bottom: 50px; }
.success-stories-grid .number { width: 55px; height: 44px; display: inline-block; text-align: center; line-height: 42px; background: rgb(60,19,109); background: linear-gradient(90deg, rgba(60,19,109,1) 0%, rgba(53,19,109,1) 47%, rgba(39,19,108,1) 100%); border-radius: 7px; border: solid 1px #4f4343; color: #fff; font-size: 21px; font-weight: 600; top: 23px; position: relative; }
.success-stories-block .number::before { position: absolute; content: ""; background: #29136c; width: 2px; height: 42px; left: 0; top: -43px; right: 0; margin: 0 auto; }
.buttons-grid button { display: inline-block; background: rgb(144,81,225); background: linear-gradient(90deg, rgba(144,81,225,1) 0%, rgba(129,81,224,1) 47%, rgba(114,81,224,1) 100%); border: none !important; padding: 13px 20px; font-weight: 600; margin: 5px; }
.about-sec.get-started .title { font-size: 36px; }
.about-sec.discover-sec .content-box .title { font-size: 34px; }
.success-stories-block-in { border-bottom: solid 2px #481c9e; }
.last-success-block .success-stories-block .success-stories-block-in { border: none; margin-top: -22px; }
.last-success-block .success-stories-block .success-stories-block-in .content { margin: 0; padding-top: 60px; }
.last-success-block.success-stories-grid .number { top: 0; position: relative; }
.last-success-block.success-stories-grid .number::before { bottom: 0; top: 43px; }
/**/
.pricing-sec { background: url('../assets/images/pricing-banner.jpg') no-repeat; background-size: cover; background-position: center; padding: 20px 0; background-attachment: fixed; padding: 100px 0; }
.pricing-sec .title { margin-bottom: 50px; }
.pyramid { width: 430px; display: flex; flex-direction: column; height: 330px; -webkit-clip-path: polygon(50% 0, 100% 100%, 0 100%); clip-path: polygon(50% 0, 100% 100%, 0 100%); }
.pyramid__section { flex: 1 1 100%; background-color: #2d136d; margin-bottom: 2px; }
.pyramid__section:nth-of-type(even) { background-color: #2d136d; }
.pyramid-cont-block .pyramid-cont h2 { margin: 0; margin-bottom: 0px; font-size: 20px; color: #fff; margin-bottom: 10px; }
.pyramid-cont-block .pyramid-cont p { margin: 0; color: #fff; }
.pyramid-cont { border-bottom: solid 2px #2d136d; padding-bottom: 20px; }
.pyramid-cont-block .pyramid-cont { position: relative; left: -153px; padding: 25px 0; padding-left: 20px; }
.pyramid-cont-block .pyramid-cont:nth-child(2) { left: -82px; padding-left: 30px; }
.pyramid-cont-block .pyramid-cont:last-child { border: none; left: 0; }
.pyramid__section { text-align: center; line-height: 103px; }
.pyramid__section span { color: #fff; font-size: 26px; font-family: inherit !important; position: relative; top: 13px; }
/**/
header .row { align-items: center; }
header .menu ul { margin: 0; padding: 0; text-align: right; }
header .menu ul li { list-style: none; display: inline-block; margin: 0 15px; }
header .menu ul li a { list-style: none; text-decoration: none; color: #000; font-size: 18px; font-weight: 500; }
header .header-in { padding: 10px 0; }
header .header-in figure img { max-width: 80px; }
header { background: #fff; position: fixed; top: 0; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; width: 100%; z-index: 9; }
header .menu button { display: none; }

/* ===== RESPONSIVE ===== */

/*1599 to 1440 */
@media (max-width: 1599px) {
}

/*1439 to 1360 */
@media (max-width: 1439px) {
.title { font-size: 32px; }
.feature-step .number { width: 65px; }
.feature-sec { padding: 80px 0; }
.about-sec.discover-sec .content-box .title { font-size: 30px; }
.about-sec.discover-sec .content-box { padding: 40px 40px; }
.about-sec.discover-sec .content-box h3 { font-size: 18px; }
.about-sec.discover-sec { padding: 70px 0; }
.pyramid-cont-block .pyramid-cont { left: -83px; }
.pyramid-cont-block .pyramid-cont:nth-child(2) { left: -20px; }
.pyramid-cont-block .pyramid-cont:last-child { padding-left: 90px; }
}

/*1359 to 1280 */
@media (max-width: 1359px) {
.about-sec { padding: 70px 0; }
.about-sec .content-box .title { font-size: 28px; }
.benefits-block { height: 270px; }
.benefits-sec .title { margin-bottom: 25px; font-size: 25px; }
}

/*1279 to 1152 */
@media (max-width: 1279px) {
.about-sec .content-box { padding: 50px 30px; }
.about-grid .about-block h3 { font-size: 20px; }
.benefits-sec { padding: 60px 0; }
.benefits-block { height: auto; }
.benefits-sec .benefits-sec-right figure img { height: 500px; object-fit: cover; object-position: bottom; }
.benefits-sec-left { padding-right: 15px; margin-bottom: 30px; }
.pricing-sec { padding: 70px 0; }
.pricing-sec .title { margin-bottom: 50px; font-size: 26px; }
.pyramid { width: 360px; height: 290px; }
.pyramid__section span { font-size: 19px; top: -4px; }
.pyramid-cont-block .pyramid-cont h2 { font-size: 17px; }
.pyramid-cont-block .pyramid-cont { padding: 25px 0; }
}

/*1151 to 1024 */
@media (max-width: 1151px) {
.feature-sec { padding: 60px 0; }
.feature-sec .title { margin-bottom: 10px; }
.success-stories-sec { padding: 70px 0; }
}

/*1023 to 992 */
@media (max-width: 1023px) {
.about-sec.discover-sec .content-box .title { font-size: 23px; line-height: 36px; margin-bottom: 30px; }
}

/*1023 to 970 */
@media (max-width: 991px) {
.main-banner aside { text-align: center; }
.main-banner aside.banner-right { margin-top: 30px; }
.title { font-size: 27px; line-height: 44px; }
.main-banner .main-banner-in .title br { display: none; }
.main-banner .main-banner-in .title { margin-bottom: 15px; }
.main-banner .main-banner-in .banner-right img { height: 340px; }
.feature-step .feature-cont h3 { font-size: 20px; }
.feature-step .number { width: 115px; }
.success-stories-grid .success-stories-block .content { margin-bottom: 20px; }
.success-stories-block-in { border-bottom: none; }
.last-success-block .success-stories-block .success-stories-block-in .content { padding-top: 30px; }
.success-stories-sec .title { margin-bottom: 30px; }
.success-stories-grid .number { display: none; }
.pyramid-cont-block .pyramid-cont { left: 7px; }
.pyramid-cont-block .pyramid-cont:nth-child(2) { left: 70px; }
.pyramid-cont-block .pyramid-cont:last-child { padding-left: 140px; }
}

/*969 to 900 */
@media (max-width: 969px) {
.about-sec .content-box .title { font-size: 25px; }
.about-sec .content-box { padding: 40px 30px; }
}

/*899 to 800 */
@media (max-width: 899px) {
.pricing-sec { text-align: center; }
.pricing-sec { padding: 50px 0; }
.pyramid { margin: 0 auto; }
.pyramid-cont-block .pyramid-cont { left: 0; }
.pyramid-cont-block .pyramid-cont:nth-child(2) { left: 0; }
.pyramid-cont-block .pyramid-cont:last-child { padding-left: 0; }
.pricing-sec .title { font-size: 23px; }
.pricing-sec aside { max-width: 100%; width: 100%; flex: 100%; }
}

/*799 to 768 */
@media (max-width: 799px) {
}

/*767 to 736 */
@media (max-width: 767px) {
.title { font-size: 23px; line-height: 37px; }
.main-banner .main-banner-in .banner-right img { height: 250px; }
.about-sec .content-box .title { font-size: 21px; line-height: 33px; }
.about-sec .content-box { padding: 30px 30px; }
.feature-step .number { width: 85px; }
.benefits-sec .benefits-sec-right figure img { height: 380px; }
header .menu button { display: block; position: absolute; right: 0; top: 20px; background: #471b9c; border: none; opacity: 1; width: 50px; height: 50px; border-radius: 60px; }
header .menu button svg { font-size: 29px; }
header .header-in { position: relative; }
header .menu ul.desk-menu { display: none; }
header .header-in figure img { max-width: 70px; }
.main-banner { margin-top: 90px; }
.offcanvas { background: #210b5c; }
.offcanvas .mobil-menu { margin: 0; padding: 0; }
.offcanvas .mobil-menu li { list-style: none; }
.offcanvas .mobil-menu li a { text-decoration: none; }
.offcanvas .offcanvas-body { padding: 0; }
.offcanvas .mobil-menu li a { padding: 10px 20px; display: block; font-size: 18px; text-transform: uppercase; color: #fff; font-weight: 600; }
.offcanvas-header .btn-close { background-color: #fff; opacity: 1; }
}

/*735 to 667 */
@media (max-width: 735px) {
}

/*666 to 600 */
@media (max-width: 666px) {
}

/*599 to 568 */
@media (max-width: 599px) {
.main-banner .main-banner-in .banner-right img { height: 210px; }
}

/*567 to 480  */
@media (max-width : 567px) {
}

/*479 to 414  */
@media (max-width : 479px) {
.benefits-sec .benefits-sec-right figure img { height: 280px; }
.about-sec.discover-sec .content-box { padding: 30px 20px; }
}

/*413 to 375*/
@media (max-width : 413px) {
.feature-step .number { width: 115px; }
.pyramid { width: 290px; height: 290px; margin: 0 auto; }
}

/*374 to 320*/
@media (max-width : 374px) {
}
